export const details = [
  {
    id: 1,
    about:
      "Beaches in Bali were closed April - June 2020. Starving for a sunset and chill, I began walking to a guesthouse's rooftop, and see a scooter make a hard u-turn - coming my way, `Oh great` I whispered aloud wondering what's to be discussed. She says she's a photographer and wants to shoot me. I gave it a shot and started shooting here and there for brands on my worst, best and bali behaviour. ",
    go: "Go on..",
  },
  {
    id: 2,
    go: "And Then?!",
  },
  {
    id: 3,
    go: "Oh damn!",
  },
  {
    id: 4,
    go: "Get it girl",
  },
];
